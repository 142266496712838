import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  Card,
  Divider,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {
  Add,
  Backspace,
  CameraAlt,
  Delete,
  HighlightOff,
  Image as ImageIcon,
  NotificationsActive,
  Remove,
  Restore,
  Room,
} from '@material-ui/icons';
import ManagePermissionsDialog, {
  getUsersFromUserGroup,
  ManagePermissionsDialogType,
} from '../design/ManagePermissionsDialog';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupsState } from '../../features/groups/selector';
import {
  getAllProjectUsersState,
  getPhysicalBuildingsState,
  getPhysicalFloorsState,
  getPhysicalLocationsState,
  getProjectState,
} from '../../features/project/selectors';
import PunchListItemMarker from './PunchListItemMarker';
import { getColorFromPunchListStatus } from './ImageWithMarkers';
import {
  DocumentTemplateType,
  FileCategoryType,
  IComment,
  IFile,
  INumberedDocumentView,
  ItemCoordinates,
  IUser,
  IUserGroup,
  PunchListStatusType,
  SecurityPermissionLevel,
} from '../../api-client/autogenerated';
import { deleteFile, uploadGeneralDocumentFile } from '../../models/api/filesystem';
import { getUserState } from '../../features/user/selectors';
import dayjs, { Dayjs } from 'dayjs';
import CommentsCard from '../comment/CommentsCard';
import { insertCommentByDocumentId } from '../../models/api/comments';
import { CustomComment } from '../comment/Comment';
import {
  documentTypeToUrl,
  generateUniqueId,
  isSurface,
  resizeImageHelper,
} from '../../scripts/utils';
import {
  deleteDocumentById,
  restoreDocumentById,
  withdrawDocumentById,
} from '../../models/api/documents';
import { getDocPermission } from '../../scripts/store-utils';
import { useHistory } from 'react-router-dom';
import ImageMarker, { MarkerComponentProps } from 'react-image-marker';
import PunchListFlagIcon from '../icons/PunchListFlag-icon';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';
import CircularLoader from '../loader/CircularLoader';
import Chip from '@material-ui/core/Chip';
import { v4 } from 'uuid';
import Webcam from 'react-webcam';
import { isDesktop } from 'react-device-detect';
import TakePhotoDialog from '../dialogs/TakePhotoDialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: { flexGrow: 1, padding: theme.spacing(3), minHeight: '100vh', overflowX: 'hidden' },
  column: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
  },
  paper: {
    marginTop: 32,
    color: theme.palette.text.secondary,
    width: '100%',
    position: 'relative',
  },
  form: {
    marginTop: 8,
    paddingBottom: 32,
  },
  title: {
    padding: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textField: {},
  labelText: { marginBottom: 8 },
  warningText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: 12,
    color: '#000000',
    marginTop: 8,
  },
  bottomContainer: {
    width: '100%',
    marginTop: 32,
  },
  divider: { paddingTop: 0, paddingBottom: 0 },
  deleteButton: {
    backgroundColor: '#ED3F26',
    '&:hover': {
      backgroundColor: '#FF5D45',
    },
  },
  tableRow: { '&:last-child td, &:last-child th': { border: 0 } },
  tableCell: {},
}));

export interface Image {
  file: File;
  image: ArrayBuffer | string;
  description: string;
  changed?: boolean;
  databaseFile: IFile;
  error?: boolean;
}

export interface PunchListItem {
  id: string;
  locationId: string;
  generalObservation: string;
  responsibleSubcontractor: string;
  dateOfObservation: Dayjs | null;
  postedDate: Dayjs;
  lastModified: Dayjs;
  cost: number | null;
  workInProgress: string;
  status: PunchListStatusType;
  isHidden: boolean;
  isDraft: boolean;
  isDraftOriginal?: boolean;
  creatorUserId?: string;

  markedLocation: ItemCoordinates | null;
  images: Image[];

  comments: IComment[];
  newComments: CustomComment[];

  // NOTIFICATIONS
  associatedUserIds: string[];
  associatedUserGroupIds: string[];

  // FOR PUBLIC PAGES ONLY
  associatedUsers?: IUser[];
  associatedUserGroups?: IUserGroup[];

  revisionNumber: number;

  error: boolean;
  uploading: boolean;
  withdrawn: boolean;
}

export type Mode = 'CREATE' | 'EDIT_ITEM' | 'EDIT_LOCATION' | 'PUBLIC';

type Props = {
  parentData: PunchListItem[];
  parentUpdate: React.Dispatch<React.SetStateAction<PunchListItem[]>>;
  index: number;
  mode: Mode;
  originalDocument?: INumberedDocumentView;
  locationImageFile?: string;

  // FOR PRINTING
  buildingName?: string;
  floorName?: string;
  locationName?: string;

  saveItem: (isDraft: boolean) => Promise<void>;
};

export default function CreatePunchListPageItem(props: Props) {
  const classes = useStyles();
  const {
    parentData,
    parentUpdate,
    index,
    mode,
    originalDocument,
    locationImageFile,
    buildingName,
    floorName,
    locationName,
    saveItem,
  } = props;

  const printMatches = useMediaQuery('print');

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);
  const groups = useSelector(getGroupsState);
  const projectUsers = useSelector(getAllProjectUsersState);
  const buildings = useSelector(getPhysicalBuildingsState);
  const floors = useSelector(getPhysicalFloorsState);
  const locations = useSelector(getPhysicalLocationsState);

  const permission = getDocPermission();

  const imageRef = useRef<HTMLImageElement>(null);

  const item = parentData[index];
  const isPublic = mode === 'PUBLIC';
  const isEditMode = mode === 'EDIT_ITEM' || mode === 'EDIT_LOCATION';
  const isDisabled =
    item.withdrawn ||
    item.isHidden ||
    (item.status === PunchListStatusType.Rejected && mode !== 'CREATE') ||
    isPublic ||
    (!item.isDraftOriginal &&
      isEditMode &&
      (!permission || permission < SecurityPermissionLevel.NUMBER_2));

  const itemLocation = locations.find((l) => l.id === item.locationId);
  const itemFloor = floors.find((f) => f.id === itemLocation?.floorId);
  const itemBuilding = buildings.find((b) => b.id === itemFloor?.buildingId);

  const [collapsed, setCollapsed] = useState(
    item.status === PunchListStatusType.Rejected || mode === 'EDIT_LOCATION',
  );

  const saveDisabled =
    item.withdrawn ||
    item.isHidden ||
    !item.generalObservation ||
    !item.dateOfObservation ||
    (!item.markedLocation && !itemBuilding?.isDefault) ||
    item.uploading ||
    (isEditMode && (!permission || permission < SecurityPermissionLevel.NUMBER_2));

  const canWithdrawItem = () => {
    if (mode === 'CREATE' || isPublic || item.withdrawn) return false;
    return permission >= 2;
  };

  const canDeleteItem = () => {
    if (mode === 'CREATE') return true;
    if (isPublic) return false;
    return permission >= 4;
  };

  // UPDATE
  const handleUpdateParent = (updateData: Partial<PunchListItem>) => {
    parentUpdate((oldData) => {
      const newData = [...oldData];
      newData[index] = { ...newData[index], ...updateData };
      return newData;
    });
  };

  const handleDeleteItem = async () => {
    const proceed = window.confirm(
      `Are you sure you want to delete ${
        item.generalObservation ? `Punch List Item ${item.generalObservation}` : 'this item'
      }?`,
    );
    if (!proceed) return;
    await deleteDocumentById(item.id);
    const newData = [...parentData];
    newData.splice(index, 1);
    parentUpdate((prev) => {
      if (prev.length === 1 && isEditMode)
        history.push(
          `/main/projects/${project!.id}/documents/${
            documentTypeToUrl[DocumentTemplateType.PunchList]
          }`,
        );
      return newData;
    });
  };

  const handleWithdrawItem = async () => {
    await withdrawDocumentById(item.id);
    handleUpdateParent({ withdrawn: true });
  };

  const handleRestoreItem = async () => {
    await restoreDocumentById(item.id);
    handleUpdateParent({ isHidden: false });
  };

  // MARK LOCATION
  const [markLocationDialogOpen, setMarkLocationDialogOpen] = useState(false);

  // IMAGES
  const [isUploadingImages, _setIsUploadingImages] = useState<boolean>(false);
  const setIsUploadingImages = (val: boolean) => {
    _setIsUploadingImages(val);
    handleUpdateParent({ uploading: val });
  };
  const [uploadProgress, setUploadProgress] = useState(0);
  const [photoDialogOpen, setPhotoDialogOpen] = useState(false);

  const webcamRef = useRef<Webcam | null>(null);
  const captureImage = useCallback(async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        try {
          const response = await fetch(imageSrc);
          const file = new File([await response.arrayBuffer()], `${v4()}.png`, {
            type: 'image/png',
          });
          setPhotoDialogOpen(false);
          await onDrop([file]);
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log('imagesrc is empty');
      }
    } else {
      console.log('webcam ref is empty');
    }
  }, [webcamRef, item.images]);

  const readFileAsync = (file: FileWithPath) => {
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const uploadFile = async (docId: string, fileToUpload: File, fileDescription?: string) => {
    return uploadGeneralDocumentFile(
      docId,
      {
        fullFileName: fileToUpload.name,
        fileType: FileCategoryType.DocumentAttachments,
        description: fileDescription,
        useMultiPartUpload: fileToUpload.size > MULTI_PART_FILE_SIZE,
        skipThumbnailGeneration: true,
      },
      fileToUpload,
      handleUploadProgress,
    );
  };

  const onDrop = async (acceptedFiles: (FileWithPath | File)[]) => {
    const newImages: Image[] = [];

    try {
      setIsUploadingImages(true);

      dispatch(blockNavigation());
      await Promise.all(
        acceptedFiles.map(async (acceptedFile) => {
          const resizedFile = await resizeImageHelper(acceptedFile);
          const { file } = await uploadFile(item.id, resizedFile, '');
          const dataUrl = await readFileAsync(resizedFile);

          if (dataUrl && file) {
            newImages.push({
              file: resizedFile,
              image: dataUrl,
              description: '',
              databaseFile: file,
            });
          }
        }),
      );

      newImages.push(...item.images);

      handleUpdateParent({
        images: newImages,
      });
    } finally {
      setIsUploadingImages(false);
      dispatch(allowNavigation());
    }
  };

  // NOTIFICATIONS
  const [managePermDialogOpen, setManagePermDialogOpen] = useState(false);
  const [defaultNotificationGroupUsers, setDefaultNotificationGroupUsers] = useState<IUser[]>([]);

  useEffect(() => {
    if (project?.punchListNotificationGroupId) {
      const defaultGroup = groups.find((g) => g.id === project.punchListNotificationGroupId);
      if (defaultGroup) setDefaultNotificationGroupUsers(getUsersFromUserGroup(defaultGroup));
    }
  }, [project]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    multiple: true,
    onDrop,
    disabled: isDisabled,
  });

  const ItemNotificationRow = (props: { name: string; company: string; email: string }) => {
    const { name, company, email } = props;

    // ISSUE: There's not a good way to differentiate groups from users in the list
    return (
      <TableRow className={classes.tableRow}>
        <TableCell align="left" className={classes.tableCell}>
          {name}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {company}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {email}
        </TableCell>
      </TableRow>
    );
  };

  const getDefaultNotificationTableRows = () => {
    return defaultNotificationGroupUsers.map((user) => (
      <ItemNotificationRow
        key={user.id}
        name={user.name}
        company={user.company?.name || 'Unknown Company'}
        email={user.email}
      />
    ));
  };

  const getItemNotificationTableRows = () => {
    let users: IUser[] = [];
    if (isPublic) {
      if (item.associatedUsers) users.push(...item.associatedUsers);
      if (item.associatedUserGroups)
        item.associatedUserGroups.forEach((group) => users.push(...getUsersFromUserGroup(group)));
    } else {
      item.associatedUserIds.forEach((userId) => {
        const newUser = projectUsers.find((pUser) => pUser.userId === userId)?.user;
        if (newUser) users.push(newUser);
      });
      item.associatedUserGroupIds.forEach((groupId) => {
        const group = groups.find((group) => group.id === groupId);
        if (group) users.push(...getUsersFromUserGroup(group));
      });
    }
    return users.map((user) => (
      <ItemNotificationRow
        key={user.id}
        name={user.name}
        company={user.company?.name || 'Company Not Found'}
        email={user.email}
      />
    ));
  };

  const handleSubmitComment = async (comment: string) => {
    if (!comment) return;
    if (isEditMode) {
      const newComment = await insertCommentByDocumentId(item.id, {
        text: comment,
        creatorUserId: user.id,
      });
      handleUpdateParent({ comments: [...item.comments, newComment] });
    } else {
      const newComment: CustomComment = {
        id: generateUniqueId(),
        creatorUser: {
          name: user.name,
          company: { name: user.company?.name || 'Company Not Found' },
        },
        text: comment,
        createdOn: dayjs().toString(),
      };
      handleUpdateParent({ newComments: [...item.newComments, newComment] });
    }
  };

  const handlePrint = () => {
    if (!project) return;
    history.push(`/main/projects/${project.id}/documents/punch-list/${item.id}/print`);
  };

  const handleSave = async (isDraft: boolean) => {
    await saveItem(isDraft);
    setCollapsed(true);
  };

  const getOptionsButtons = () => {
    if (item.isHidden) {
      return (
        <>
          <Hidden smDown>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Restore />}
              onClick={handleRestoreItem}
              style={{ marginLeft: 16 }}
            >
              Restore Item
            </Button>
          </Hidden>
          <Hidden mdUp>
            <IconButton onClick={handleRestoreItem} style={{ marginLeft: 8 }}>
              <Restore style={{ color: 'white' }} />
            </IconButton>
          </Hidden>
        </>
      );
    }

    return (
      <>
        {canWithdrawItem() ? (
          <>
            <Hidden smDown>
              <Button
                className={classes.deleteButton}
                variant="contained"
                color="primary"
                startIcon={<Backspace />}
                disabled={isDisabled}
                onClick={handleWithdrawItem}
                style={{ marginLeft: 16 }}
              >
                Withdraw Item
              </Button>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                className={classes.deleteButton}
                disabled={isDisabled}
                onClick={handleWithdrawItem}
                style={{ marginLeft: 8 }}
              >
                <Backspace style={{ color: 'white' }} />
              </IconButton>
            </Hidden>
          </>
        ) : null}
        {canDeleteItem() ? (
          <>
            <Hidden smDown>
              <Button
                className={classes.deleteButton}
                variant="contained"
                color="primary"
                startIcon={<Delete />}
                disabled={isDisabled}
                onClick={() => handleDeleteItem()}
                style={{ marginLeft: 16 }}
              >
                Delete Item
              </Button>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                className={classes.deleteButton}
                disabled={isDisabled}
                onClick={() => handleDeleteItem()}
                style={{ marginLeft: 8 }}
              >
                <Delete style={{ color: 'white' }} />
              </IconButton>
            </Hidden>
          </>
        ) : null}
      </>
    );
  };

  return (
    <Card className={`${classes.paper}`}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: 16,
          paddingLeft: 24,
          paddingRight: 16,
          paddingBottom: 16,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', maxWidth: '75%' }}>
          {collapsed && (
            <Add
              fontSize="large"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            />
          )}

          {!collapsed && (
            <Remove
              fontSize="large"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            />
          )}

          {item.isDraft ? (
            <Chip
              size="small"
              label="Draft"
              color="secondary"
              style={{ marginLeft: 8, marginRight: 8 }}
            />
          ) : null}

          <Typography
            className={classes.title}
            variant="h2"
            style={{
              color: item.generalObservation === '' ? '#979797' : '#464546',
              marginLeft: 8,
              marginBottom: 8,
            }}
          >
            {item.error && item.generalObservation === '' && (
              <span style={{ color: '#ED3F26' }}>* </span>
            )}
            {item.generalObservation === ''
              ? 'New Punch List Item'
              : `${item.generalObservation}${
                  originalDocument?.revisionNumber ? `-R${originalDocument.revisionNumber}` : ''
                }`}{' '}
          </Typography>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {mode === 'EDIT_LOCATION' && (
            <Hidden xsDown>
              <Button
                color="primary"
                variant="contained"
                onClick={handlePrint}
                style={{ marginLeft: 16 }}
              >
                Export Item
              </Button>
            </Hidden>
          )}
          <Hidden xsDown>{getOptionsButtons()}</Hidden>
        </div>
      </div>

      <Hidden smUp>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: 12,
            marginRight: 24,
          }}
        >
          {getOptionsButtons()}
        </div>
      </Hidden>

      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <form className={`${classes.form}`} hidden={collapsed && !printMatches}>
          <Grid container spacing={3} style={{ paddingLeft: 24, paddingRight: 24 }}>
            {!itemBuilding?.isDefault ? (
              <Grid item xs={12} style={{ marginLeft: 6 }} className={'hide-on-print'}>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={!locationImageFile || isDisabled}
                  onClick={() => setMarkLocationDialogOpen(true)}
                  startIcon={<Room />}
                  style={{ height: 'inherit', paddingTop: 2, paddingBottom: 2 }}
                >
                  MARK LOCATION IN ROOM
                </Button>
                <div
                  className={classes.warningText}
                  style={{ textTransform: 'none', fontSize: 12 }}
                >
                  Location must be marked before publishing.*
                </div>
              </Grid>
            ) : null}

            {locationImageFile && item.markedLocation && (
              <Grid item xs={12} style={{ maxWidth: 800 }}>
                <ImageMarker
                  src={locationImageFile}
                  markers={[
                    { left: item.markedLocation!.x * 100, top: item.markedLocation!.y * 100 },
                  ]}
                  markerComponent={(props: MarkerComponentProps) => (
                    <PunchListFlagIcon
                      transformed
                      color={getColorFromPunchListStatus(item.status)}
                      width={25}
                      height={37}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid
              item
              container
              spacing={3}
              style={{ paddingLeft: 16, paddingRight: 16, maxWidth: 1550 }}
            >
              <Grid item xs={12}>
                <div className={`${classes.column} nobreak`}>
                  <Typography
                    variant="body1"
                    className={classes.labelText}
                    style={item.error && item.generalObservation === '' ? { color: '#ED3F26' } : {}}
                  >
                    Punch List Item Description
                    {item.error && item.generalObservation === '' && <span> (required)</span>}*
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    multiline
                    placeholder="Punch List Item Description"
                    className={classes.textField}
                    disabled={isDisabled}
                    value={item.generalObservation}
                    onChange={(e) => handleUpdateParent({ generalObservation: e.target.value })}
                    error={item.error && item.generalObservation === ''}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={`${classes.column} nobreak`}>
                  <Typography variant="body1" className={classes.labelText}>
                    Date of Observation*
                  </Typography>
                  <KeyboardDatePicker
                    required
                    inputVariant="outlined"
                    InputProps={{ style: { padding: 0 } }}
                    className={classes.textField}
                    format="MM/DD/YYYY"
                    disabled={isDisabled}
                    value={item.dateOfObservation}
                    onChange={(d) => handleUpdateParent({ dateOfObservation: d })}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={`${classes.column} nobreak`}>
                  <Typography variant="body1" className={classes.labelText}>
                    Status*
                  </Typography>
                  <Select
                    variant="outlined"
                    value={item.status}
                    disabled={isDisabled}
                    onChange={(e) => {
                      handleUpdateParent({ status: e.target.value as PunchListStatusType });
                    }}
                    displayEmpty
                    style={{ marginTop: 4 }}
                  >
                    <MenuItem value="" disabled>
                      <span style={{ color: '#BFBFBF' }}>Status</span>
                    </MenuItem>
                    <MenuItem value={PunchListStatusType.New}>New</MenuItem>
                    <MenuItem value={PunchListStatusType.InProgress}>In Progress</MenuItem>
                    <MenuItem value={PunchListStatusType.ReadyForVerification}>
                      Ready for Verification
                    </MenuItem>
                    <MenuItem value={PunchListStatusType.Rejected}>Rejected</MenuItem>
                    <MenuItem value={PunchListStatusType.Accepted}>Accepted</MenuItem>
                  </Select>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={`${classes.column} nobreak`}>
                  <Typography variant="body1" className={classes.labelText}>
                    Cost
                  </Typography>
                  <CurrencyTextField
                    variant="outlined"
                    fullWidth
                    id="project-number"
                    placeholder="Cost"
                    name="Project Number"
                    className={classes.textField}
                    value={item.cost ? item.cost / 100 : undefined}
                    disabled={isDisabled}
                    currencySymbol="$"
                    minimumValue="0"
                    outputFormat="number"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(e: any, value: number) => handleUpdateParent({ cost: value * 100 })}
                    InputLabelProps={{
                      style: { overflow: 'hidden' },
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={`${classes.column} nobreak`}>
                  <div
                    className={classes.labelText}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="body1">Responsible Subcontractor</Typography>
                  </div>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    placeholder="Responsible Subcontractor"
                    disabled={isDisabled}
                    value={item.responsibleSubcontractor}
                    onChange={(e) =>
                      handleUpdateParent({ responsibleSubcontractor: e.target.value })
                    }
                    className={classes.textField}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={`${classes.column} nobreak`}>
                  <Typography variant="body1" className={classes.labelText}>
                    Work in Progress
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    multiline
                    disabled={isDisabled}
                    placeholder="Work in Progress"
                    value={item.workInProgress}
                    onChange={(e) => handleUpdateParent({ workInProgress: e.target.value })}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                {/*<div className={`${classes.column} nobreak`}>*/}
                {/*  <Typography variant="body1" className={classes.labelText}>*/}
                {/*    Last Modified*/}
                {/*  </Typography>*/}
                {/*  <TextField*/}
                {/*    variant="outlined"*/}
                {/*    fullWidth*/}
                {/*    required*/}
                {/*    disabled*/}
                {/*    placeholder="Last Modified"*/}
                {/*    value={item.lastModified.format('MM/DD/YYYY hh:mm A z')}*/}
                {/*  />*/}
                {/*</div>*/}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    maxWidth: 500,
                  }}
                >
                  <Typography>Last Modified:</Typography>
                  <Typography>{item.lastModified.format('MM/DD/YYYY hh:mm A')}</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                {/*<div className={`${classes.column} nobreak`}>*/}
                {/*  <Typography variant="body1" className={classes.labelText}>*/}
                {/*    Created On*/}
                {/*  </Typography>*/}
                {/*  <TextField*/}
                {/*    variant="outlined"*/}
                {/*    fullWidth*/}
                {/*    required*/}
                {/*    disabled*/}
                {/*    placeholder="Created On"*/}
                {/*    value={item.postedDate.format('MM/DD/YYYY hh:mm A z')}*/}
                {/*  />*/}
                {/*</div>*/}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    maxWidth: 500,
                  }}
                >
                  <Typography>Created On:</Typography>
                  <Typography>{item.postedDate.format('MM/DD/YYYY hh:mm A')}</Typography>
                </div>
              </Grid>
            </Grid>

            {/* IMAGES */}
            {(!isPublic || item.images.length > 0) && (
              <Grid
                item
                xs={12}
                style={{ paddingTop: 8, paddingBottom: 8 }}
                className={'hide-on-print'}
              >
                <Divider />
              </Grid>
            )}

            <Grid item xs={12} style={{ marginTop: 8, paddingLeft: 16 }}>
              {!isPublic && (
                <>
                  {isDesktop || isSurface() ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={isDisabled}
                      startIcon={<CameraAlt />}
                      onClick={() => setPhotoDialogOpen(true)}
                      style={{ marginBottom: 16 }}
                    >
                      Take Photo
                    </Button>
                  ) : null}
                  <div {...getRootProps()} style={{ width: 'fit-content' }}>
                    <input {...getInputProps()} />
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<ImageIcon />}
                      disabled={isDisabled}
                    >
                      SELECT IMAGES
                    </Button>
                  </div>
                  <div className={`${classes.warningText} hide-on-print`}>
                    ONLY *.JPG AND *.PNG IMAGES WILL BE ACCEPTED
                  </div>
                </>
              )}

              <Grid
                item
                container
                spacing={3}
                direction="column"
                style={{ marginTop: 15, maxWidth: 500 }}
              >
                {isUploadingImages ? <CircularLoader /> : null}
                {item.images.map((image, i) => {
                  const canDelete =
                    !isDisabled &&
                    (image.databaseFile?.creatorUserId === user.id ||
                      user.isSiteAdmin ||
                      image.databaseFile?.isVerified === false);
                  return (
                    <Grid
                      key={image.file?.name || image.databaseFile?.name}
                      item
                      xs
                      className={'nobreak'}
                      style={{ position: 'relative' }}
                    >
                      {image.image ? (
                        <img
                          src={image.image.toString()}
                          alt=""
                          style={{ borderRadius: 5, objectFit: 'cover', maxWidth: '96%' }}
                        />
                      ) : (
                        <Typography style={{ fontSize: 20, fontWeight: 500, alignSelf: 'center' }}>
                          Failed to load image
                        </Typography>
                      )}

                      <Tooltip arrow placement="top" title={canDelete ? 'Remove Image' : ''}>
                        <IconButton
                          disabled={!canDelete}
                          onClick={async () => {
                            if (image.databaseFile) {
                              await deleteFile(item.id, image.databaseFile.id);
                            }

                            let newImages = [...item.images];
                            newImages.splice(i, 1);

                            handleUpdateParent({ images: newImages });
                          }}
                          style={{ position: 'absolute', padding: 0, marginLeft: 4 }}
                        >
                          <HighlightOff />
                        </IconButton>
                      </Tooltip>

                      <TextField
                        size="small"
                        variant="outlined"
                        placeholder="Description"
                        value={image.description}
                        disabled={isDisabled}
                        onChange={(e) => {
                          let newImages = [...item.images];
                          newImages[i].description = e.target.value;
                          newImages[i].changed = true;

                          handleUpdateParent({ images: newImages });
                        }}
                        style={{ marginTop: 10, width: '96%' }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: 8, paddingBottom: 8 }}>
              <Divider />
            </Grid>

            {/* COMMENTS */}
            <Grid item xs={12}>
              <CommentsCard
                handleSubmit={handleSubmitComment}
                comments={[...item.comments, ...item.newComments]}
                overrideTitle="Item"
                isPunchListPage
              />
            </Grid>

            <Grid item xs={12} style={{ paddingTop: 8, paddingBottom: 8 }}>
              <Divider />
            </Grid>

            {/* NOTIFICATIONS */}
            <Grid container spacing={3} style={{ paddingLeft: 16, paddingRight: 16 }}>
              {!isPublic && (
                <Grid
                  item
                  xs={12}
                  className={classes.warningText}
                  style={{
                    fontStyle: 'normal',
                    fontSize: 15,
                    fontWeight: 500,
                    overflowX: 'auto',
                  }}
                >
                  Default Punch List Notification Group
                  <TableContainer component={Paper} style={{ overflowX: 'auto', maxHeight: 300 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontSize: 15 }} align="left">
                            Name
                          </TableCell>
                          <TableCell style={{ fontSize: 15 }} align="left">
                            Company
                          </TableCell>
                          <TableCell style={{ fontSize: 15 }} align="left">
                            Email
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{getDefaultNotificationTableRows()}</TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
              {!isPublic && (
                <Grid item xs={12} style={{ marginTop: 8, marginBottom: 8 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<NotificationsActive />}
                    disabled={isDisabled}
                    onClick={() => {
                      setManagePermDialogOpen(true);
                    }}
                    style={{ height: 'inherit', paddingTop: 2, paddingBottom: 2 }}
                  >
                    ADD USER TO NOTIFICATION LIST
                  </Button>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                className={classes.warningText}
                style={{
                  fontStyle: 'normal',
                  fontSize: 15,
                  fontWeight: 500,
                  overflowX: 'auto',
                }}
              >
                Item-Specific Notification Group
                <TableContainer component={Paper} style={{ overflowX: 'auto', maxHeight: 300 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontSize: 15 }} align="left">
                          Name
                        </TableCell>
                        <TableCell style={{ fontSize: 15 }} align="left">
                          Company
                        </TableCell>
                        <TableCell style={{ fontSize: 15 }} align="left">
                          Email
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>{getItemNotificationTableRows()}</TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              {!isEditMode ? (
                <Grid
                  item
                  xs={12}
                  className={classes.warningText}
                  style={{
                    fontStyle: 'normal',
                    fontSize: 15,
                    fontWeight: 500,
                    overflowX: 'auto',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={saveDisabled}
                    onClick={() => handleSave(true)}
                    style={{ marginRight: 16 }}
                  >
                    Save as Draft
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={saveDisabled}
                    onClick={() => handleSave(false)}
                  >
                    Publish this Item
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </form>
      </MuiPickersUtilsProvider>
      <ManagePermissionsDialog
        type={ManagePermissionsDialogType.AssociatedUsers}
        dialogOpen={managePermDialogOpen}
        closeDialog={() => {
          setManagePermDialogOpen(false);
        }}
        associatedUsers={item.associatedUserIds}
        setAssociatedUsers={(userIds) => {
          handleUpdateParent({ associatedUserIds: userIds });
        }}
        associatedGroups={item.associatedUserGroupIds}
        setAssociatedGroups={async (groupIds) => {
          handleUpdateParent({ associatedUserGroupIds: groupIds });
        }}
      />
      {locationImageFile && (
        <PunchListItemMarker
          open={markLocationDialogOpen}
          handleClose={() => setMarkLocationDialogOpen(false)}
          file={locationImageFile}
          existingFlag={item.markedLocation}
          onSubmit={(result) => handleUpdateParent({ markedLocation: result })}
        />
      )}
      {isDesktop || isSurface() ? (
        <TakePhotoDialog
          open={photoDialogOpen}
          close={() => setPhotoDialogOpen(false)}
          webcamRef={webcamRef}
          takePhoto={captureImage}
        />
      ) : null}
    </Card>
  );
}
